<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getSellReport"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 id="sellTitle"  class="mb-0 font-weight-normal">
              {{ $t('report.txtsellbyProduct') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div class="col-md-2 col-4 col-sm-3 text-right text-success">
              <CButton id="sellExportButton" v-if="isExport" v-on:click="exportSellReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <div style="overflow-x:auto;">
                <DataTable id="sellDatatable" style="white-space: nowrap;" :items="items()" :fields="fields" :loading="loading" hover
                  border>
                  <template #discountAmount="{ item }">
                    <td v-if="item.discountAmount > 0.00" class="text-right text-danger">-{{ item.discountAmount }}</td>
                    <td v-else class="text-right text-dark">{{ item.discountAmount }}</td>
                  </template>
                </DataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getSellReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'), _classes: 'text-left text-dark font-weight-normal', },
        { key: 'receiptNumber', label: this.$i18n.t('receiptNumber'), _classes: 'text-left text-dark font-weight-normal', },
        { key: 'productPLU', label: this.$i18n.t('pluCode'), _classes: 'text-left text-dark font-weight-normal', },
        { key: 'name', label: this.$i18n.t('productName'), _classes: 'text-left text-dark font-weight-normal', },
        {
          key: 'quantitySKU',
          label: this.$i18n.t('quantity'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'price',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'optionPrice',
          label: this.$i18n.t('optionTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'totalAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },

  },
  created() {
    this.getSellReport()
  },
  methods: {
    ...util,
    items() {
      let data = this.data
      let detail = []
      let name = ''
      let PLUCode = ''
      let quantitySKU = ''
      let price = ''
      let netAmount = ''
      let discountAmount = 0
      let totalAmount = ''
      let classes = ''
      let discount = 0
      let discountBillPerQty = 0
      let discountBillPerQtyCal = 0
      let optionPrice = 0
      let total = 0

      for (let i = 0; i < data.length; i++) {
        if (data[i].receiptItems.productPLU === undefined) {
          name = ''
          PLUCode = ''
          quantitySKU = ''
          price = ''
          netAmount = ''
          discountAmount = ''
          totalAmount = ''
          total = ''
        } else {
          let orderedOptions = data[i].receiptItems.orderedOptions;
          if (orderedOptions == null) {
            optionPrice = 0.00;
          } else {
            optionPrice = 0.00; // กำหนดค่าเริ่มต้นเป็น 0.00

            for (let j = 0; j < orderedOptions.length; j++) {
              if (orderedOptions[j].price !== undefined && orderedOptions[j].price !== 0) {
                // ถ้า price ไม่ได้กำหนดเป็น undefined และไม่เท่ากับ 0
                optionPrice += orderedOptions[j].price;
              }
            }
          }
          if (data[i].receiptItems.discountBillPerQty !== null) {
            discountBillPerQty = data[i].receiptItems.discountBillPerQty
          }

          if (data[i].receiptItems.discountAmount !== null) {
            discountAmount = data[i].receiptItems.discountAmount
          }

          quantitySKU = data[i].receiptItems.quantitySKU

          discountBillPerQtyCal = discountBillPerQty * quantitySKU

          discount = discountBillPerQtyCal + discountAmount
          total =
            data[i].receiptItems.netAmount -
            data[i].receiptItems.discountBillPerQty

          name = data[i].receiptItems.productPLU.name
          PLUCode = data[i].receiptItems.productPLU.PLUCode

          price = util.convertCurrency(data[i].receiptItems.price)
          totalAmount = util.convertCurrency(data[i].receiptItems.totalAmount)
          netAmount = util.convertCurrency(total)
          discountAmount = util.convertCurrency(discount)
        }

        if (data[i].receiptStatus === 'V') {
          classes = 'text-danger'
        } else if (data[i].receiptStatus === 'A') {
          classes = 'text-black'
        } else if (data[i].receiptStatus === 'W') {
          classes = 'text-warning'
        }

        let receiptNumber = ''
        if (data[i].receiptNumber == '') {
          receiptNumber = '-'
        } else {
          receiptNumber = data[i].receiptNumber
        }
        detail.push({
          date: moment(String(data[i].payReceiptAt)).format('DD/MM/YYYY HH:mm:ss'),
          receiptNumber: receiptNumber,
          productPLU: PLUCode,
          name: name,
          quantitySKU: quantitySKU,
          price: price,
          totalAmount: totalAmount,
          discountAmount: discountAmount,
          netAmount: netAmount,
          _classes: classes,
          optionPrice: util.convertCurrency(optionPrice)
        })
      }
      return detail
    },
    getSellReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: this.shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      axios({
        url: '/receipt/v1.0/productsellreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportSellReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = { shopObjectId: shopObjectId }

      report({
        url: '/receipt/v1.0/productsellreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานการขายตามรายการสินค้า.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
